import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import "slick-carousel/slick/slick.css"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdN1LIfAAAAAFOhb3__o4pf0z04fJTEx2RWQDgv">
      {element}
    </GoogleReCaptchaProvider>
  )
}
